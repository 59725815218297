

import {LoginPayload} from '@/modules/auth/shared/payloads/login-payload';
import {actionsTypes, ModuleState} from '../shared/state';
import {environment} from '@/environment/environment';
import {LoginRequest} from '@/modules/auth/shared/requests/login-request';
import Vue from 'vue';
import {logger} from '@/shared/services';
import {Component} from 'vue-property-decorator';
import {Environment} from '@/shared/types';

@Component({
    props: {
        callback: String,
    },
})
export default class LoginScreen extends Vue {
    public loginData: LoginRequest = new LoginRequest();
    public valid: boolean = false;
    public show: boolean = false;
    public resetPassword: boolean = false;
    public store: ModuleState = this.$store.state.authState;
    public callback!: string;

    public emailRules: any[] = [
        (v: string) => !!v || 'E-mail jest wymagany',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail musi być poprawny',
    ];

    public passwordRules: any[] = [
        (v: string) => !!v || 'Hasło jest wymagane',
        (v: string) => (v && v.length >= 4) || 'Hasło musi mieć minimum 4 znaki',
    ];

    get timer() {
        return this.store.timer;
    }

    get calming() {
        return this.store.calming;
    }

    get loading() {
        return this.store.loading;
    }

    get loginError() {
        return this.store.error;
    }

    public mounted() {
        this.show = true;
    }

    public created() {
        if (environment === Environment.Development) {
            this.loginData.email = 'admin@acme.example';
            this.loginData.password = 'test12';
        }
    }

    public submitForm() {
        const form: any = this.$refs.form;

        form.validate();

        if (this.valid) {
            this.$store.dispatch(actionsTypes.LOGIN_USER, this.loginData)
                .then((response: LoginPayload) => {
                    if (response && response.access_token) {
                        this.$vuetify.theme.dark = response.user.dark_theme;
                        localStorage.setItem('dark_theme', String(response.user.dark_theme));

                        this.$store.state.authState.user.form_tooltips = response.user.form_tooltips;

                        if (!this.store.redirect) {
                            if (this.callback) {
                                window.location.href = this.callback;
                            } else {
                                this.$router.push({
                                    name: response.user && response.user.first_login ? 'first-setup' : 'home',
                                });
                            }
                        } else {
                            this.store.redirect = false;
                            this.$router.back();
                        }
                    }
                })
                .catch((err) => logger.error(err));
        }
    }
}

