
import {FormRules} from '@/shared/validation/form-rules';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {httpClient, logger} from '@/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {AxiosResponse} from 'axios';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ImageInput from '@/shared/components/elements/image-input.vue';
import {dataURItoBlob} from '@/shared/helpers';
import {Snackbar} from '@/shared/types';

@Component({
    components: {
        FormActions,
        ImageInput,
    }
})
export default class ActivateUser extends Vue {
    public requestData: any = {};
    public valid: boolean = false;
    public error: string = '';
    public errorMessages: any = {};
    public avatar: string | ArrayBuffer | null = '';
    public avatarFilename: string = '';
    public loading: boolean = false;
    public dataURItoBlob = dataURItoBlob;
    public formRules: FormRules = new FormRules();
    public cropDialog: boolean = false;
    public selectedFile: Blob | null = null;

    @Prop(String) public email!: string;
    @Prop(String) private token!: string;

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            this.$delete(this.errorMessages, name);
            if (this.errorMessages.length > 0) {
                return;
            }
            return this.error = '';
        }

    }

    public passwordConfirmation(v: string) {
        return (!this.requestData || (v === this.requestData.password)) || 'Hasła się nie zgadzają';
    }

    public submitForm() {
        this.loading = true;
        this.error = '';
        this.errorMessages = {};

        const formData = new FormData();
        const keys = Object.keys(this.requestData);

        if (this.email) {
            formData.append('email', this.email);
        }
        if (this.token) {
            formData.append('token', this.token);
        }
        keys.forEach((key) => {
            if (this.requestData[key]) {
                formData.append(key, this.requestData[key]);
            }
        });

        httpClient.post(
            '/api/v1/users/activate',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response: AxiosResponse) => {
            this.loading = false;
            if (response.status === 200) {
                const snackbar = {
                    type: 'success',
                    text: 'Konto zostało utworzone',
                } as Snackbar;
                this.$store.commit('SHOW_SNACKBAR', snackbar);
                this.$router.push({name: 'login'});
            }
        }).catch((e: ErrorPayload) => {
            this.loading = false;
            this.error = e.message;
            this.errorMessages = e.errors;
            logger.error(e);
        });
    }

    public imageCropped(cropped: string) {
        this.cropDialog = false;
        if (cropped) {
            this.requestData.avatar_photo = this.dataURItoBlob(cropped, this.avatarFilename);
            this.avatar = cropped;
        } else {
            this.selectedFile = null;
            this.requestData.avatar_photo = null;
            this.avatar = null;
        }
    }

    public closeCropDialog() {
        this.cropDialog = false;
        if (!this.requestData.avatar_photo) {
            const avatarInput = this.$refs.avatarInput as HTMLInputElement;
            avatarInput.blur();
            this.selectedFile = null;
            this.avatar = null;
        } else {
            this.selectedFile = this.requestData.avatar_photo;
            const fr = new FileReader();
            fr.onload = () => {
                this.avatar = fr.result;
            };
            if (this.selectedFile) {
                fr.readAsDataURL(this.selectedFile);
            }
        }
    }

    public onImageChange(file: File) {
        if (file) {
            this.avatarFilename = file.name;
            const fr = new FileReader();
            fr.onload = () => {
                this.avatar = fr.result;
                this.cropDialog = true;
            };
            fr.readAsDataURL(file);
        } else {
            this.avatar = null;
            const avatarInput = this.$refs.avatarInput as HTMLInputElement;
            avatarInput.blur();
        }
    }
}

