

import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { httpClient } from '@/shared/services';
import { FormRules } from '@/shared/validation/form-rules';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
@Component({
    components: {
        FormActions,
    }
})
export default class LoginScreen extends Vue {
    public valid: boolean = false;
    public hostname: string|null = '';
    public firstRun: boolean = false;
    public formRules: FormRules = new FormRules();

    public created() {
        this.hostname = localStorage.getItem('hostname');
        this.firstRun = (this.hostname === null);
    }
    public submitForm() {
        localStorage.setItem('hostname', (this.hostname || ''));
        this.$router.push({name: 'login'}).then(() => {
            httpClient.defaults.baseURL = `https://${this.hostname}.d360.pl`;
        });
    }
}

