
import {FormRules} from '@/shared/validation/form-rules';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {httpClient} from '@/shared/services';
import {Snackbar} from '@/shared/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

@Component({
    components: {
        FormActions,
    }
})
export default class ResetPassword extends Vue {
    public formRules: FormRules = new FormRules();
    public loading: boolean = false;
    public valid: boolean = false;
    public form: any = null;
    public email: string = '';
    public error: string = '';
    public errorMessages: any = {};

    public mounted() {
        this.form = this.$refs.resetForm;
    }

    public async submitForm() {
        if (this.form) {
            await this.form.validate();
            this.error = '';
            this.errorMessages = {};
            this.loading = true;
            httpClient
                .post('/api/v1/forgot-password', {email: this.email})
                .then((response) => {
                    this.loading = false;
                    if (response.status === 200 && response.data) {
                        const data = response.data;
                        const snackbar = {
                            type: 'success',
                            text: data.message,
                        } as Snackbar;

                        this.$store.commit('SHOW_SNACKBAR', snackbar);
                        this.$router.push({name: 'login'});
                    }
                })
                .catch((e: ErrorPayload) => {
                    this.loading = false;
                    this.error = e.message;
                    this.errorMessages = e.errors;
                });
        }
    }
}

