
import {FormRules} from '@/shared/validation/form-rules';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {httpClient, logger} from '@/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {AxiosResponse} from 'axios';
import {Snackbar} from '@/shared/types';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

@Component({
    components: {
        FormActions,
    }
})
export default class NewPassword extends Vue {
    @Prop(String) public email!: string;

    public requestData: any = {};
    public valid: boolean = false;
    public error: string = '';
    public errorMessages: any = {};
    public form: any = null;
    public loading: boolean = false;
    public formRules: FormRules = new FormRules();
    @Prop(String) private token!: string;

    public passwordConfirmation(v: string) {
        return (!this.requestData || (v === this.requestData.password)) || 'Hasła się nie zgadzają';
    }

    public mounted() {
        this.form = this.$refs.newPasswordForm;
    }

    public async submitForm() {
        if (this.form) {
            await this.form.validate();
            this.loading = true;
            this.error = '';
            this.errorMessages = {};
            httpClient.post('/api/v1/reset-password', {
                ...this.requestData,
                email: this.email,
                token: this.token,
            }).then((response: AxiosResponse) => {
                this.loading = false;
                if (response.status === 200) {
                    const data = response.data;
                    const snackbar = {
                        type: 'success',
                        text: data.message,
                    } as Snackbar;
                    this.$store.commit('SHOW_SNACKBAR', snackbar);
                    this.$router.push({name: 'login'});
                }
            }).catch((e: ErrorPayload) => {
                this.loading = false;
                this.error = e.message;
                this.errorMessages = e.errors;
                logger.error(e);
            });
        }
    }
}

