
import {Component, Vue} from 'vue-property-decorator';
import {Environment, EnvironmentColor} from '@/shared/types';

@Component
export default class LoginPage extends Vue {
    public store = this.$store.state.suthState;
    public Environment = Environment;

    get cordova() {
        return (window as any).cordova;
    }

    get isLight() {
        return !this.$vuetify.theme.dark;
    }

    set isLight(value) {
        this.$vuetify.theme.dark = !value;
        localStorage.setItem('dark_theme', String(!value));
    }

    get environment() {
        return this.$store.state.environment;
    }

    get environmentColor(): EnvironmentColor {
        switch (this.environment) {
            case Environment.Staging:
                return EnvironmentColor.Staging;
            case Environment.Testing:
                return EnvironmentColor.Testing;
            case Environment.Development:
                return EnvironmentColor.Development;
            case Environment.Production:
                return EnvironmentColor.Production;
            default:
                throw new Error(`Nieznane środowisko: ${this.environment}`);
        }
    }

    get appVersion() {
        return this.$store.state.version;
    }

    get appVersionUrl() {
        return this.$store.state.versionUrl;
    }

    get hostname() {
        const hostname = localStorage.getItem('hostname');
        return `${hostname}.d360.pl`;
    }
}
